import * as R from "ramda"
import * as React from "react"

import Button from "../components/Buttons"
import { H2 } from "../components/Typography"
import Layout from "../components/Layout"
import SEO from "../components/seo"
import { naira } from "../libs/utils"

// eslint-disable-next-line react/prop-types
function CourseDetails({ pageContext }) {
  const course = pageContext
  const hasGuildsCertificate = Boolean(course?.certificate)

  return (
    <Layout>
      <SEO title={`Course: ${course.courseTitle}`} />
      <section className="container mx-auto mt-10 mb-24">
        <H2 className="px-6 md:px-0 mb-8">Course Details</H2>
        <div
          className="flex bg-gray-600"
          style={{
            backgroundImage: `url(${(course.image || [{}])[0]?.file?.url})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
          }}
        >
          <div className="flex-1"></div>
          <div
            className="flex-1 bg-gray-800 pt-48 pb-32 select-none bg-opacity-50"
            style={{
              WebkitBackdropFilter: "blur(4px)",
              backdropFilter: "blur(4px)",
            }}
          >
            <H2
              className="transform -translate-x-12"
              style={{ textShadow: "2px 4px 2px rgba(0,0,0,0.2) " }}
            >
              <span className="text-white">{course.courseTitle} Program</span>
            </H2>
          </div>
        </div>
      </section>

      <section className="flex flex-col px-6 md:px-0 md:flex-row gap-12 mb-32 items-start container mx-auto select-none">
        <div className="px-6 py-8 w-full order-3 md:order-1 lg:max-w-sm bg-gray-50">
          <section className="mb-8 ">
            <h6 className="font-bold mb-2">Class Time</h6>
            {course?.classTime?.nodes?.map(node => {
              return (
                <div key={node?.period} className="flex">
                  <li className="block opacity-75 flex-1">{node?.period}</li>
                  <li className="block opacity-75 flex-1">{node?.timeRange}</li>
                </div>
              )
            })}
          </section>

          <section className="mb-8">
            <h6 className="font-bold mb-2">Locations</h6>
            <li className="block opacity-75">Excellent Centre - Lagos</li>
            <li className="block opacity-75">
              Creative Centre &nbsp;- Port-Harcourt
            </li>
          </section>

          {![null, undefined, "NONE"].includes(course.certification) && (
            <section className="mb-8">
              <h6 className="font-bold mb-2">Certification</h6>
              <li className="block opacity-75">{course.certification}</li>
            </section>
          )}

          <section className="mb-12">
            <div className="text-sm tracking-widest">
              {hasGuildsCertificate ? "COURSE ONLY" : "PRICE"}
            </div>
            <li className="block font-bold text-2xl">
              &#x20a6;{naira(course.price)}
            </li>

            {hasGuildsCertificate && (
              <>
                <span
                  className={
                    "block text-sm tracking-widest font-bold my-4 text-primary"
                  }
                >
                  OR PLUS
                </span>

                <div className="border border-gray-400 flex space-x-4 bg-white py-4 px-6 relative">
                  <div className=" space-y-2">
                    <div className="text-sm">{course.certificate.label}</div>
                    <li className="block font-bold text-2xl">
                      &#x20a6;{naira(course.certificate.cost + course.price)}
                    </li>
                  </div>
                  <img
                    src={require("../images/city-and-guilds.png").default}
                    alt={"City & Guilds Logo"}
                    className={`object-contain w-16 h-16`}
                  />
                </div>
              </>
            )}
          </section>

          <div className="">
            <a href="https://forms.gle/MG3EQdW9K3tkVaAb8">
              <Button primary className="w-full">
                <span className="flex py-2">ENROLL NOW</span>
              </Button>
            </a>
          </div>
        </div>

        <div className="md:order-2 max-w-xl">
          <h6 className="font-bold text-xl lg:text-2xl mb-2">
            About this course
          </h6>
          <article className="opacity-75 font-normal">
            {R.compose(
              //R.tap(R.partial(console.log, ['Finally'])),
              renderRichText,
              safeDescription
            )(course?.description?.raw)}
          </article>
          <article className="mt-8">
            <h6 className="font-bold mb-2">Requirements for enrolment: </h6>
            <ol className="list-decimal pl-5 md:pl-4 opacity-75 mb-8">
              <li className="mb-1 text-sm">Resume</li>
              <li className="mb-1 text-sm">Passport photo (2 copies)</li>
              <li className="mb-1 text-sm">Application letter </li>
              <li className="mb-1 text-sm">Any prove of prior education </li>
              <li className="mb-1 text-sm">Admission fee N10,000</li>
            </ol>
            <h6 className="font-bold mb-2">
              Admission fee covers the following:
            </h6>
            <ul className="list-decimal pl-5 md:pl-4 opacity-75 max-w-md">
              <li className="mb-1 text-sm">Admission letter </li>
              <li className="mb-1 text-sm">
                Complete Chef gear (coat, apron, pant/trouser, hand towel)
              </li>
              <li className="mb-1 text-sm">Course Handouts </li>
              <li className="mb-1 text-sm">
                School Provides all materials needed for training except during
                student&apos;s test and exam
              </li>
            </ul>
          </article>
        </div>
      </section>
    </Layout>
  )
}

const safeDescription = data => {
  try {
    return JSON.parse(data)
  } catch (err) {
    console.info(err.message)
    return { nodeType: "text", value: "Loading description failed." }
  }
}

const buildCompFor = (name, props = {}) =>
  function buildRichContentTags(children) {
    console.log({ name, children })
    return React.createElement(
      name,
      props,
      ["b", "i", "u"].includes(name) ? children : renderRichText(children)
    )
  }

const nodesTypesMap = {
  document: buildCompFor("article"),
  paragraph: buildCompFor("p", { className: "mb-4" }),
  "unordered-list": buildCompFor("ul", {
    className: "list-disc pl-4",
  }),
  "ordered-list": buildCompFor("ul", { className: "list-decimal pl-4" }),
  "list-item": buildCompFor("li", { className: "mb-1" }),
  bold: buildCompFor("b"),
  italic: buildCompFor("i"),
  underline: buildCompFor("u"),
}

const renderRichText = a => {
  // if (R.is(String, a)) return a
  if (R.is(Array, a)) return a.map(renderRichText)
  if (a.nodeType === "text")
    return (a.marks || []).reduce((acc, mark) => {
      if (nodesTypesMap[mark.type]) return nodesTypesMap[mark.type](acc)
      return acc
    }, a.value)

  if (nodesTypesMap[a.nodeType]) return nodesTypesMap[a.nodeType](a.content)

  console.log("No render available for", a.nodeType)
}

export default CourseDetails
